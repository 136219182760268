function addLocalPath(path) {
  if (window.location.host !== 'localhost:1234') return path;
  return path + '.html';
}

function getLangLink(pageName) {
  const pagePath = window.location.pathname;
  const partToPaste = pagePath.includes('ru') ? 'ru' : pagePath.includes('en') ? 'en' : '';

  if (!partToPaste) return addLocalPath(`./${pageName}`);
  return addLocalPath(`./${partToPaste}-${pageName}`);
}

function redirectToPage(pageName) {
  document.location.href = getLangLink(pageName);
}
function goToTri() {
  redirectToPage('triathlon');
}
function goToRun() {
  redirectToPage('run');
}

const triThumb = document.querySelector('.tri-link');
const runThumb = document.querySelector('.run-link');
const heroBlock = document.querySelector('.hero');
const arrowsBlock = document.querySelector('.hero__choose');

let scalePlus = 50;
let scaleMinus = 50;

if (window.screen.width <= 768) {
  const screenHight = document.documentElement.clientHeight;
  let neededHeightForRedirect = (screenHight - 72) * 0.9; //90% of client height - header
  const basicBlockHeight = (screenHight - 72) / 2;

  const endFnc = e => {
    let thumbTri = triThumb.getBoundingClientRect();
    let thumbRun = runThumb.getBoundingClientRect();

    if (thumbTri.height > thumbRun.height) {
      goToTri();
    } else if (thumbRun.height > thumbTri.height) {
      goToRun();
    } else {
      console.log(e.target);
    }

    triThumb.style.height = null;
    runThumb.style.height = null;
    arrowsBlock.style = null;

    return;
  };

  heroBlock.addEventListener(
    'touchstart',
    () => {
      heroBlock.addEventListener('touchend', endFnc);
    },
    true
  );

  let startY;
  let arrowBasicY;

  heroBlock.addEventListener(
    'touchstart',
    e => {
      startY = e.touches[0].clientY;
      arrowBasicY = arrowsBlock.getBoundingClientRect().y;
    },
    true
  );

  heroBlock.addEventListener(
    'touchmove',
    e => {
      let currentY = e.touches[0].clientY;
      let difference = currentY - startY;
      triThumb.style.height = `${basicBlockHeight + difference}px`;
      runThumb.style.height = `${basicBlockHeight - difference}px`;
      arrowsBlock.style.top = `${arrowBasicY + difference + 7}px`;
    },
    true
  );
  triThumb.addEventListener('click', goToTri);
  runThumb.addEventListener('click', goToRun);
} else {
  triThumb.addEventListener('click', goToTri);
  runThumb.addEventListener('click', goToRun);
}
